@import url("./fontastic-font.css");
@import "../theme/base-css.scss";

:root {
    --blueTextColor: #5CA2F7;
    --textSecondary: #D1D2DA;
    --blueButtonColor: #2A86F3;
    --buttonSecondaryBgActive: #161D40;
    --blueButtonHoverColor: #2a68f3;
    --textWhiteColor: #FDFDFD;
    --primaryDarkBg: #080D29;
    --whiteBg: #EFEFEF;
    --greenText: #5AD86E;
    --bodyBg: #1E2442;

    --founderCardModalBg: linear-gradient(142.04deg, #262626 -2.92%, #1C1B1B 93.19%), #FBFBFB;;
}

body {
    background: var(--bodyBg);
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.c8F93A4 {
    color: #8F93A4;
}

.hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.custom-padding {
    padding: 10px 90px;
    @media (max-width: 768px) {
        padding: 10px 20px;
    }
}

.offer-card-container {
    width: 24% !important;
    margin: 6px 6px;
    @media (max-width: 1440px) {
        margin: 4px 4px;
    }
    @media (max-width: 768px) {
        width: 100% !important;
    }
}

.offer-card {
    width: 100% !important;
}

.offer-card-border {
    background: linear-gradient(153deg, #545C88 30.42%, rgba(71, 73, 86, 0) 74.72%);
    border-radius: 8px;
    padding: 1px;
    width: auto;
}

.concierge-founders.offer-card-border {
    background: linear-gradient(153deg, #FFB038 30.42%, #663F05 74.72%);
    border-radius: 8px;
    padding: 1px;
    width: auto;
}

.featured-offer-card-border {
    background: linear-gradient(159deg, #FFB038, #663F05 , #663F05);
    box-shadow: 0px 7px 6px rgb(33 34 44 / 25%);
    border-radius: 8px;
}

.dropdown-menu {
    background-color: #34416C;
    border-radius: 0px;
    padding: 0;
    overflow: auto;
    max-height: 180px;
    padding: 8px 16px;
    box-shadow: 0px 0px 1px rgba(21, 45, 75, 0.2), 0px 8px 12px rgba(21, 45, 75, 0.1), 0px 0px 1px rgba(21, 45, 75, 0.2), 0px -8px 12px rgba(21, 45, 75, 0.1);
    .dropdown-item {
        align-items: center;
        display: flex;
        color: #fff;
        height: 40px;
        cursor: pointer;
        padding: 8px 32px;
        width: 210px;
        border-bottom: 1px solid transparent;
    }
    .dropdown-item:hover {
        background-color: #2F3352;
        border-bottom: 1px solid #4F546E;
    }
}

.custom-slider {
    .item {
        width: 100%;
    }
}

.darkBg {
    background-color: var(--primaryDarkBg);
}

.secondary-dark {
    background-color: #111739;
}

.whiteBg {
    background-color: var(--whiteBg);
}

.blue-text {
    color: var(--blueTextColor);
}

.green-text {
    color: var(--greenText);
}

.white-text {
    color: var(--textWhiteColor);
}

.custom-button {
    border-radius: 4px;
    &.primary {
        background-color: var(--blueButtonColor);
        color: var(--textWhiteColor);
        &.outline {
            background-color: transparent;
            border: 1px solid #5CA2F7;
        }
    }
    &.primary:hover {
        background-color: var(--blueButtonHoverColor);
    }

    &.secondary {
        background-color: var(--buttonSecondaryBgActive);
        color: var(--textWhiteColor);
        &.outline {
            border: 1px solid #646980;
        }
    }
    &.secondary:hover {
        background-color: var(--blueButtonHoverColor);
    }
    a {
        color: inherit;
    }

    &.disabled {
        background-color: #393F5C;
        color: #8F93A4 !important;
    }

    &.secondary-founder {
        background-color: rgba(255, 255, 255, 0.1);;
        color: var(--textWhiteColor);
        &.outline {
            border: 1px solid #646980;
        }
    }

}

.border-primary {
    border: 1px solid #5CA2F7;
}

.hideTab .tab-container .nav.nav-tabs {
    display: none;
}

.details-page.tab-container {
    .nav-tabs {
        display: flex;
        flex-flow: row;
        border: unset;
    }
    .nav-item {
        width: 100%;
    }
    .nav-link {
        background-color: inherit !important;
        border: none;
        &.active .custom-tab {
            border: 1px solid #3281FF;
            color: var(--blueTextColor);
            border-radius: 2px;
        }
    }
    .custom-tab {
        background-color: rgba(21, 102, 241, 0.09);
        color: var(--textSecondary);
        height: 32px;
        border: 1px solid #646980;
        border-radius: 2px;
    }
}

.redemption.tab-container {
    width: 865px;
    @media (max-width: 768px) {
        width: 100%;
    }
    .nav-pills {
        -ms-overflow-style: none;
        scrollbar-width: none;
        overflow: scroll;
        display: flex;
        flex-flow: row;
        border: unset;
        margin-bottom: 15px;
        @media (max-width: 768px) {
            padding: 10px 20px;
        }
        .nav-item {
            margin-right: 8px;
        }
    }
    .nav-pills::-webkit-scrollbar {
        display: none;
    }
    .nav-link {
        background-color: #152D4B;
        border-radius: 55px;
        color: #C4C4C4;
        padding: 10px 25px;
        &.active {
            background: linear-gradient(96.33deg, #1566F1 37.03%, #2A86F3 77.17%);
            color: #FFFFFF;
        }
        span {
            white-space: nowrap;
        }
    }
}


//Modal styling
.modal-backdrop.show {
    background-color: rgba(17, 18, 24, 0.9) !important;
}

.custom-modal.token-expired {
    max-width: 380px !important;
    .mat-dialog-container {
        background-color: #1C2344;
        padding: 0px;
    }
}

.founders-modal-bg {
    .mat-dialog-container {
         background: var(--founderCardModalBg) !important;
     }
 }

.footer-modals {
    width: 580px !important;
    max-width: 580px !important;
    max-height: 80vh;
    color: #fff;
    @media (max-width: 768px) {
        max-width: 100vw !important;
        bottom: 70px;
        max-height: 100vh !important;
    }
    @media (max-width: 386px) {
        max-width: 100vw !important;
    }
    .mat-dialog-container {
        overflow: auto !important;
        background-color: #1F2440 !important;
        color: #fff;
        padding: 0;
    }
}

.custom-modal {

    &.foundercard-modal {
        background: url("./assets/images//celebrateBg.svg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        @media (max-width: 768px) {
            background: url("./assets/images/celebrateBgMobile.svg");
            background-position: bottom;
            background-size: cover;
            background-repeat: no-repeat;
        }
    }

    .mat-dialog-container {
        background-color: rgba(17, 18, 24, 0.9);
    }

    .modal-content {
        background-color: transparent !important;
    }

    .modal-bg {
        background-color: rgba(17, 18, 24, 0.9);
        border-radius: 12px;
        padding: 32px;
    }

    &.foundercard-modal .modal-bg {
        padding: 0;
    }

    &.coupon-modal {
        max-width: 345px;
    }

    &.foundercard-modal.coupon-modal {
        max-width: 100vw;
        height: 100%;
        .modal-content {
            max-width: 493px;
            margin: auto;
            &::before {
                content: '';
                height: 32px;
                width: 32px;
                border-radius: 50px;
                background-color: rgba(17, 18, 24, 0.9) !important;
                background-blend-mode: difference;
                position: absolute;
                left: -16px;
                z-index: 1;
                top: 40%;
            }
            &::after {
                content: '';
                height: 32px;
                width: 32px;
                border-radius: 50px;
                background-color: rgba(17, 18, 24, 0.9) !important;
                background-blend-mode: difference;
                position: absolute;
                right: -16px;
                top: 40%;
            }
        }
        &::before, &::after {
            content: none;
        }

    }

    &.coupon-modal::before {
        content: '';
        height: 32px;
        width: 32px;
        border-radius: 50px;
        background-color: rgba(17, 18, 24, 0.9) !important;
        background-blend-mode: difference;
        position: absolute;
        left: -16px;
        z-index: 1;
    }
    &.coupon-modal::after {
        content: '';
        height: 32px;
        width: 32px;
        border-radius: 50px;
        background-color: rgba(17, 18, 24, 0.9) !important;
        background-blend-mode: difference;
        position: absolute;
        right: -16px;
    }

    .custom-dashed-border {
        border: 1px dashed rgba(196, 203, 215, 0.2);
        &.top {
            border-bottom: 0;
            border-right: 0;
            border-left: 0;
        }
    }

}

.text-decoration-underline {
    text-decoration: underline;
}

.truncate-2-lines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.cdk-overlay-backdrop {
    background: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(8px);
}

.contact-modal {
    width: 800px !important;
    max-width: 800px !important;
}

.back-confirmation-modal {
    width: 493px !important;
    max-width: 493px !important;
    min-height: 328px;
}

.select-offer-modal {
    width: 580px !important;
    max-width: 580px !important;
}

.select-offer-modal , .contact-modal, .back-confirmation-modal {
    max-height: 80vh;
    @media (max-width: 768px) {
        position: absolute !important;
        max-width: 100vw !important;
        bottom: 70px;
    }
    @media (max-width: 386px) {
        max-width: 100vw !important;
    }
    .mat-dialog-container {
        overflow: auto !important;
        background-color: #1F2440;
        color: #fff;
        padding: 0;
    }
}

.back-confirmation-modal {
    .mat-dialog-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }   
}

.close-icon {
    width: 13px;
    height: 13px;
    margin-left: auto;
}

.custom-border {
    border: 1px solid #4F546E;
    &.top {
        border-top: 1px solid #4F546E !important;
        border-bottom: 0;
        border-right: 0;
        border-left: 0;
    }
    &.right {
        border-right: 1px solid #4F546E !important;
        border-top: 0;
        border-bottom: 0;
        border-left: 0;
    }
    &.bottom {
        border-bottom: 1px solid #4F546E !important;
        border-top: 0;
        border-right: 0;
        border-left: 0;
    }
}

.custom-backdrop-styling {
    height: calc(100vh - 100px);
    bottom: 0;
    top: unset;
}

.authenticationModal {
    width: 420px !important;
    max-width: 420px !important;
    @media (max-width: 768px) {
      max-width: 90vw !important;
    }
    @media (max-width: 386px) {
      max-width: unset !important;
    }
    .mat-dialog-container {
      overflow: visible !important;
      background: #1F2440;
      padding: 41px 41px 0px 41px;
    }
}
   
.border-rounded-2 {
    border-radius: 2px;
}

.warning {
    &.warning-border {
        border: 1px solid #FE6650;
    }
    &.warning-text {
        color: #FE6650;
    }
}

.white-variant-light {
    color: #E7E8EC;
}

.focus-active-border:focus-within, .focus-active-border:focus-visible, .focus-active-border:hover {
    border: 1px solid #3281FF !important;
    border-radius: 2px;
}

.form-control:focus {
    color: #fff;
}

@media (max-width: 768px) {
    .justify-content-center-xs {
        justify-content: center;
    }   
}

.w-10px {
    width: 10px;
}

.right.carousel-control, .left.carousel-control {
    display: none;
}

.carousel-indicators button {
    width: 10px !important;
    height: 8px !important;
    background: rgba(217, 217, 217, 0.33);
    border-radius: 8px;
    border: 0px !important;
    align-self: center;
    &.active {
        background: #1566F1;
        border-radius: 4px;
        width: 24px !important;
        height: 8px !important;
    }
}

img {
  image-rendering: auto;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
  image-rendering: -webkit-optimize-contrast;
}

app-milestone-rewards  {
    .mat-tab-header {
        border-bottom: 0;
        .mat-tab-label-container {
            flex-grow: unset;
            margin: auto;
        }
        .milestone-label {
            height: 36px;
            color: #E7E8EC;
            padding: 6px 16px;
        }
        .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled) ,.mat-tab-label-active {
            background-color: #5CA2F7 !important;
            box-shadow: 0px 8px 20px rgba(18, 17, 39, 0.1);
            border-radius: 8px;
            opacity: 1;
        }
    }
    .milestone-tab {
        .mat-tab-body-content::-webkit-scrollbar {
            display: none;
        }
        
        .mat-tab-body-content {
            -ms-overflow-style: none;
            scrollbar-width: none;
        }
    }
    .mat-ink-bar {
        display: none;
    }
    .mat-tab-header-pagination-chevron {
        border-color: rgb(255 255 255 / 80%);
    }
    .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
        border-color: rgb(255 255 255 / 38%);
    }
    .blue-text:hover {
        color: var(--blueTextColor) !important;
    }
}

.founderscard {
    .loader.progress:before {
        background-image: linear-gradient(90deg,rgba(255,255,255,0),#62636A,rgba(255,255,255,0)) !important;
    }
}

.loader.progress:before {
    background-image: linear-gradient(90deg,rgba(255,255,255,0),#434F75,rgba(255,255,255,0)) !important;
}

.grey2-container {
    background: linear-gradient(142.04deg, #262626 -2.92%, #1C1B1B 93.19%);
    border-radius: 8px;
}

.grey2-container.rounded-bottom-0 {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.grey2-container.rounded-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}


.mar-left-80 {
    margin-left: 80px;
}

.border-transparent {
    border: 1px solid transparent !important;
}

.theme-black.bs-datepicker {
    background-color: #111;
    box-shadow: 0px 2px 2px rgb(0 0 0 / 10%);
    border-radius: 0px 0px 4px 4px;
    .bs-datepicker-container {
        padding: 0;
        .bs-datepicker-body {
            border: 0;
            table td span {
                color: #F8F9F9;
                &.is-other-month {
                    color: #f8f9f961;
                }
                &:hover {
                    background-color: #e9edf0;
                    color: #111111;
                }
                &.disabled {
                    background-color: #111;
                    color: #f8f9f931;
                    cursor: not-allowed;
                }
            }
        }
    }
}


.concierge-secondary-bg {
    background: #0000001a;
}

.concierge-greyish-bg {
    background: #2d2d2f !important;
}